<template>
  <div class="f-col gap-3">
    <vf-checkbox
      v-for="({ id, label, items }) in filter.options"
      :key="id"
      v-model="model"
      :disabled="!items"
      :value="id"
    >
      <span class="flex items-center gap-1">
        <span class="line-clamp-3">
          {{ label }}
        </span>
        <span class="text-sm">
          ({{ items }})
        </span>
      </span>
    </vf-checkbox>
  </div>
</template>

<script lang="ts" setup>
import type { CheckboxFilter } from '#types/filters'

defineProps<{
  filter: CheckboxFilter
  loading?: boolean
}>()

const model = defineModel<string[]>()
</script>
