<template>
  <div class="grid cols-6 gap-4">
    <vf-color-filter
      v-for="({ color, id, label }, i) in filter.options"
      :key="id + i"
      v-model="model"
      :id
      :color
      :value="id"
    >
      {{ label }}
    </vf-color-filter>
  </div>
</template>

<script lang="ts" setup>
import type { ColorFilter } from '#types/filters'

defineProps<{
  filter: ColorFilter
  loading?: boolean
}>()

const model = defineModel<string[]>()
</script>
